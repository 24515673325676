import React from "react";

const FrequentQuestions=()=>{
    return (
 <div class="container-fluid bg-gray" id="accordion-style-1">
	<div class="container">
		<section>
			<div class="row">
				<div class="col-12">
					<h1 class="text-black mb-4 text-center ">Frequently Asked Questions</h1>
				</div>
				<div class="col-10 mx-auto">
					<div class="accordion" id="accordionExample">
						<div class="card">
							<div class="card-header" id="headingOne">
								<h5 class="mb-0">
							<button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
							  <i class="fa fa-amazon main"></i><i class="fa fa-angle-double-right mr-3"></i> How Long Does It Take To Rank Higher On Google?
							</button>
						  </h5>
							</div>

							<div id="collapseOne" class="collapse show fade" aria-labelledby="headingOne" data-parent="#accordionExample">
								<div class="card-body">
								A: It depends on many factors. The time it takes to see
								 results from SEO can vary greatly depending
								  on numerous factors, such as the industry,
								   competition, and the starting point of your
								    website's optimisation. It generally takes
									 around three to six months for visible improvements
									  in search engine rankings, but this timeline
									   may differ based on the complexity of the
									    project and the level of competition in
										 your niche. SEO is an ongoing process
										  that requires ongoing effort and commitment
										   to achieve and maintain optimal results . Some take days, others weeks and others months. The sooner you start, the better.								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" id="headingTwo">
								<h5 class="mb-0">
							<button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
							 <i class="fa fa-plus main"></i><i class="fa fa-angle-double-right mr-3"></i>Why Does My Website Need SEO?
							</button>
						  </h5>
							</div>
							<div id="collapseTwo" class="collapse fade" aria-labelledby="headingTwo" data-parent="#accordionExample">
								<div class="card-body">
								The higher you rank on Google, the more website visits you get, leading to more inquiries and eventually more sales. The logic is simple: if they can’t find you, they can’t buy from you. PLUS  it will save you money each month on paid advertising such as Google Ads and Social Media.								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" id="headingThree">
								<h5 class="mb-0">
							<button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
							  <i class="fa fa-expeditedssl main"></i><i class="fa fa-angle-double-right mr-3"></i>What’s The Difference Between SEO and Google Ads?
							</button>
						  </h5>
							</div>
							<div id="collapseThree" class="collapse fade" aria-labelledby="headingThree" data-parent="#accordionExample">
								<div class="card-body">
								SEO = you pay us a small monthly fee to rank your website as HIGH as possible organically (It takes time but it’s the most cost effective, and most effective, marketing strategy).

Google Ads = you pay Google each time someone clicks on your ad (Quick, but expensive).

If you switch your Google Ads off, all traffic are gone. With SEO, the work we do for you stays forever, it’s an investment into your website and business.								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" id="headingFour">
								<h5 class="mb-0">
							<button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
							  <i class="fa fa-envelope main"></i><i class="fa fa-angle-double-right mr-3"></i>How much does SEO cost in 2024?
							</button>
						  </h5>
							</div>
							<div id="collapseFour" class="collapse fade" aria-labelledby="headingFour" data-parent="#accordionExample">
								<div class="card-body">
								Determining the exact cost of SEO in Cape Town in 2024 can vary depending on various factors. SEO pricing is often influenced by the competitiveness of the industry, the specific goals of the business, and the level of expertise required. Additionally, the pricing structure may differ among different SEO agencies or professionals in Cape Town. It is advisable to reach out to multiple providers and request personalized quotes that take into account your specific requirements. By doing so, you can ensure that you receive accurate and tailored pricing information for SEO services in Cape Town in 2024								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" id="headingFive">
								<h5 class="mb-0">
							<button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
							  <i class="fa fa-envelope main"></i><i class="fa fa-angle-double-right mr-3"></i>What is local SEO?
							</button>
						  </h5>
							</div>
							<div id="collapseFive" class="collapse fade" aria-labelledby="headingFive" data-parent="#accordionExample">
								<div class="card-body">
								Local SEO is a subset of regular SEO that focuses specifically on improving your business's visibility in local search results on Google. It's vital for businesses that operate in specific geographical areas and want to be found by potential customers in those locations. Local SEO strategies include optimising your website for local keywords, creating location-specific content, and managing local business listings, among others

</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" id="headingSix">
								<h5 class="mb-0">
							<button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseFour">
							  <i class="fa fa-envelope main"></i><i class="fa fa-angle-double-right mr-3"></i>Can I handle SEO on my own?
							</button>
						  </h5>
							</div>
							<div id="collapseSix" class="collapse fade" aria-labelledby="headingSix" data-parent="#accordionExample">
								<div class="card-body">
								While it is tempting to try it yourself, the truth is that these things are far better when left to the right SEO professionals. The reason is that SEO is not an overnight topic. You need time and dedication to learn it. You ought to hire an SEO professional if you need good results.
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" id="headingSeven">
								<h5 class="mb-0">
							<button class="btn btn-link collapsed btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseFour">
							  <i class="fa fa-envelope main"></i><i class="fa fa-angle-double-right mr-3"></i>What is PPC, and is it better than SEO?
							</button>
						  </h5>
							</div>
							<div id="collapseSeven" class="collapse fade" aria-labelledby="headingSeven" data-parent="#accordionExample">
								<div class="card-body">
								PPC means Pay Per Click. It is a type of internet marketing where you pay a certain fee when your content ad is clicked on. PPC is considered a short-term strategy because it relies on pay per click. Conversely, SEO has more upfront cost but is a good long-term strategy because it gives consistent results with time.
								</div>
							</div>
						</div>
					</div>
				</div>	
			</div>
		</section>
	</div>
    </div>
    );
}

export default FrequentQuestions;