import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/commons/Header";
import Footer from "./components/commons/Footer";
import Careers from "./components/Careers";
import E404 from "./components/commons/404";
import About from "./components/about/About";
import Contact from "./components/Contact";
import Quotation from "./components/Quotation";
import Service from "./components/Service";
import Blog from "./components/blog/Blog";
import Blog1 from "./components/blog/Blog1";
import Blog2 from "./components/blog/Blog2";
import Home from "./components/Home";
import Request from "./components/Request";
import Applications from "./components/Applications";
import Portfolio from "./components/Portifolio/Portfolio";
import Graphics from "./components/Portifolio/GraphicsPortfolio";
import Website from "./components/Portifolio/WebsitePortifolio";
import PortRouter from "./components/Portifolio/PortRouter";
import Seo from "./components/Services/SEO";
import WebsiteDevelopment from "./components/Services/Website-development";
import CloudServices from "./components/Services/Amazon-web-services-salutions";
import MobileApps from "./components/Services/MobileAppdevelopment";
import GoogleAds from "./components/Services/GoogleAds";
import SearchEngineMarketing from "./components/Services/SearchEngineMarketing";
import SocialMediaMarketing from "./components/Services/SocialMediaMarketing";
import Digital from "./components/blog/DigitalTransformation"
import SeoCom from "./components/blog/SeoCom";
import SuccesStories from "./components/SucessStories";

import TagManager from 'react-gtm-module'
import Leads from "./components/Services/Lead-Generation";

const tagManagerArgs = {
  gtmId: 'GTM-NMTKWQX6'
}
TagManager.initialize(tagManagerArgs)
// import ScrollToTop from "./components/scrollTop/index";

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="services" element={<Service />} />
        <Route path="contact" element={<Contact />} />
        <Route path="request" element={<Request />} />
        {}
        <Route path="akiltech-portifolio" element={<PortRouter />} />

        <Route path="graphics-portifolio" element={<Graphics />} />
        <Route path="website-portifolio" element={<Website />} />
        <Route path="quotation" element={<Quotation />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog1" element={<Blog1 />} />
        <Route path="seo-south-africa" element={<Seo/>} />
        <Route path="website-development-capetown" element={<WebsiteDevelopment/>} />
        <Route path="search-engine-marketing-capetown" element={<SearchEngineMarketing/>} />
        <Route path="social-media-marketing-south-africa" element={<SocialMediaMarketing/>} />
        <Route path="cloud-services-capetown" element={<CloudServices/>} />
        <Route path="mobile-application-development-capetown" element={<MobileApps/>} />
        <Route path="google-ads-agency" element={<GoogleAds/>} />
        <Route path="lead-generation" element={<Leads/>} />
        <Route path="website-development-cost" element={<Blog2 />} />
        <Route path ="benefits-of-seo" element={<SeoCom/>}/>
        <Route path ="akiltech-success-stories" element={<SuccesStories/>}/>
        <Route path="what-is-digital-transformation" element={<Digital />} />
        <Route path="career" element={<Careers />} />
        <Route path="akiltech-portfolio" element={<Portfolio />} />
        <Route path="apply" element={<Applications />} />
        <Route path="*" element={<E404 />} />
        {/* </ScrollToTop> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
