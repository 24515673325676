import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Apply2 from "../commons/Apply2";
import KhloHome from "../../Case-studies/khloe-home-decor.pdf";
import CarportCape from "../../Case-studies/carport-capetown.pdf";
import SwimmingPool from "../../Case-studies/swimming-pool-installation-capetown.pdf";
import EastgateDental from "../../Case-studies/eastgate-dental.pdf"
import Swimming from "../../icons/swimming-pool-installation-capetown.png"
import Download from "../../icons/download-case-study.png"
import Khloe from "../../icons/khloe-home-decor-zimbabwe.png"
import Carports from "../../icons/carports-capetown.png"
import Eastgate from "../../icons/eastgate.png"
import TopFooter from "../commons/TopFooter";
import FrequentQuestions from "../commons/FrequentQuestions";
import icon1 from "../../icons/icons8-website-100.png";
import icon2 from "../../icons/icons8-web-marketing-100.png";
import icon3 from "../../icons/icons8-search-100.png";
import icon4 from "../../icons/icons8-sales-growth-100.png";
import icon5 from "../../icons/email.png";
const Seo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      {/* <!-- Slider Area Start--> */}
      <Helmet>
        <title>SEO Company|South Africa|Zimbabwe|Zambia </title>‍
        <meta name="description" content="Find all the best quality products your pet may need" />
        <meta property="og:title" content="Pets - Products" />
        <meta property="og:description" content="Best Products for your pet" />
        <meta property="og:image" content="url_to_image"/>
         <meta property="og:url"  content="pets.abc"/>
        <meta property="og:site_name" content="Pets - Products" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />

      </Helmet>
      <div className="slider-area">
        <div className="single-sliders slider-height d-flex align-items-center">
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-7 col-md-6">
                <div className="hero-caption">
                  <h1>Seo</h1>

                  <p>
                    A Website That Doesn't Bring Client Is Useless! Rank on the first page of Google, Boost your online visibility, outrank competitors and improve search engine rankings with our proven strategies. Whether you want more traffic, more sales or both – we take the time to understand your goals.
                  </p>
                  <ul>
                    <li> Increase your website traffic



                    </li>
                    <li> Get more leads & customers</li>


                  </ul>
                  <Link to="https://calendly.com/mushaitapiwanashe/briefing-session" className="btn hero-btn">
                    GET MY FREE PROPOSAL
                  </Link>
                </div>
              </div>
              {/* <!-- hero shape 2--> */}

            </div>
          </div>
        </div>
        <div className="  d-flex ">
          <div className="container position-relative">
            <div className="row ">
              <div className="col-xl-5 col-lg-6 col-md-6  mouse_scroll  "></div>
              {/* <!-- hero shape 2--> */}
              <div className="">
                <div className="header-social text-white ">
                  <a className="text-white" href="#a">
                    Follow us on:
                  </a>
                  <a
                    href="https://www.facebook.com/akiltechspecialities/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/akiltech_inc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-instagram-square"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/akiltech-incoparated"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-linkedin-in"></i>
                  </a>
                  <a
                    href="https://twitter.com/AkilTech_inc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-twitter-square"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- social --> */}
      </div>

      {/* <!-- Slider Area End -->
    <!-- Services Area Start --> */}
      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-25">
                <span>What We Do</span>
                <h2>Make Your Website Become Your Best Salesperson </h2>

              </div>
            </div></div><div>
            <p>It's no surprise that many organizations hope to attract clients through
              their website and finding a company that can secure a spot on the highly
              coveted first page of Google and transform website traffic into customers
              is truly a dream come true. Did you know that the first page of Google captures a whopping 71-92% of all
              web traffic, with a sharp decline to only 6% on the second page? This
              highlights the immense importance of ranking on the first page of Google but many individuals , businesses and bloggers face challenges.
              <strong> Let’s face it, you’re likely here because of one or more of the
                following reasons</strong>
            </p>
            <div className="row">
              <div className="col-lg-6">
                <div className="listy justify-content-left" align="left"><ul className="ras align-items-left">
                  <img src={icon3} className="text-center" /><li className="ras"> Your website isn’t<br /> being found online.</li>
                  <img src={icon1} /><li className="ras">Your website traffic has <br />diminished recently and you don’t know why.</li>

                </ul></div></div>
              <div className="col-lg-6">
                <div className="listy justify-content-left" align="left"><ul className="ras align-items-left">
                  <img src={icon2} /> <li className="ras">Suddenly, your volume of incoming calls<br /> and leads has declined.</li>
                  <img src={icon4} />   <li className="ras"> You’re starting a new website and want<br /> to ramp up new business quickly.</li>

                </ul></div>
              </div>
            </div></div>

          <p>If any of these four pain points ring a bell, rest assured, you’ve come
            to the  <a id="lik" href="/seo" >SEO company</a>. Running a business in today’s competitive
            online landscape has become more difficult and thats’s a reason why<strong> Akiltech Inc is currently
              serving a handfull of SEO clients</strong> assisting  small, medium and
            established businesses to rank as HIGH as possible on Google. We will turn your website into a revenue generating tool for yor business by
            bringing your <strong>target audience to your website</strong> and getting them to convert into customers.
            Schedule a free briefing session with one of our <a id="lik" href="https://calendly.com/mushaitapiwanashe/briefing-session" id="lik">SEO consultants</a> and show up ready to discuss potential SEO options & next steps for your website to become your primary source of lead generation.
          </p>
        </div>




      </section>
      <div className="container-fluid call-bg">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9  col-md-12">
            {/* <!-- Section Tittle --> */}
            <div className="section-tittle text-center text-white mb-60">
              <h2>We get you found, rank Higher on Google  </h2>
              <p className="mb-55">
                Don’t let another day go by where your competitors are looking down on you! It’s time to be discovered!
              </p>
              <a href="https://calendly.com/mushaitapiwanashe/briefing-session" className="border-btn2">
                Get Clients with SEO
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="call-blue text-center">

        <h2 className="text-white text-center mt-5">
          We are passionate about serving our clients !
        </h2>
        <h5 className="text-white text-center">Case Studies - Our successful campaigns</h5>

      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">
            <img src={Swimming} className="img-fluid" alt="" />
            <div className="cat-cap">
              <h4 className="mt-3">
                <a href="services"><strong>Search Marketing Case Study: Pool Installation and Repairs</strong></a>
              </h4>

              <p className="mt-3">A South African company offering pool installation , pool building , pool repairs to pool cleaning
                services commissioned Akiltech’s services to setup it's digital footprint ,online presence , grow it’s rankings on Google and  increase engagement with its target audience across digital platforms. </p>
            </div>
            <a href={SwimmingPool} download="Swimming Pool Installation Capetown Case Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">
            <img src={Carports} className="img-fluid" alt="" />
            <div className="cat-cap mt-3">
              <h4>
                <a href="services"><strong>Search Engine Optimization Case Study: Carports & Shadeports Contractors</strong></a>
              </h4>

              <p className="mt-3">A South African company providing residential and commercial carports and shadeports installation services in Western Cape. It commissioned Akiltech’s services to setup online presence, increase Google market share, rankings and online conversions. </p>
            </div>
            <a href={CarportCape} download="Carports-Capetown-Case-Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">

            <img src={Khloe} className="img-fluid" alt="" />

            <div className="cat-cap mt-3">
              <h4>
                <a href={KhloHome} download="Khole-home-decor"><strong>Website Dev & GBM Case Study: Home Interiors | Curtains</strong></a>
              </h4>
              <p className="mt-3">
                Khloe's Home & Décor is a Zimbabwean private owned company specialising in Home Interiors.Core business is curtaining,  providing to both households and organisations. It was founded in 2017 by a young woman who had a passion for home decor and office improvements.Khloe commissioned Akiltech’s services to setup google and internet presence.
              </p>
            </div>
            <a href={KhloHome} download="Khole-home-decor Case Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">

            <img src={Eastgate} className="img-fluid" alt="" />

            <div className="cat-cap mt-3">
              <h4>
                <a href="services"><strong>Website Dev Case Study: Pool Installation and Repairs</strong></a>
              </h4>
              <p>
                A Dental practice in Harare CBD which provides a full spectrum of dental services. It commissioned Akiltech’s services to setup it's digital footprint ,online presence and increase it’s visibility on Googele.
              </p>
            </div>
            <a href={EastgateDental} download="Eastgate Dental Case Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>



      </div>
      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-25">
                <span>Why Search Engine Optimization is a Great Investment</span>
                <h2>We Practice What We Preach to Provide Maximum ROI </h2>

              </div>
            </div>
            <p>As a business owner you want better profit margins for every investment. SEO saves you money. Consider SEO as an employee: SEO never clocks out. SEO never calls in sick. SEO never takes a holiday. SEO works for you around the clock, even when you’re sleeping.

              SEO won’t answer the phone but it will keep the phone ringing. SEO
              done right will funnel customers to your website and put them in front of your products
              , services and brand.  </p>
          </div>
          <div className="row">
            <p><strong>It is our business to make sure your website is found by search engines and therefore
              by your potential customers. Together we make sure your Website is compatible,
              visible and ranking on Top. Don’t settle for mediocre rankings when you can dominate the search results with our tailored SEO solutions.

              Contact us today and let’s discuss how we can help you reach your goals.</strong></p>





          </div>


        </div>

      </section>

      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-25">
                <span>Our SEO Services</span>
                <h2>Increase Your Search Rankings and Get Discovered More Online </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/custom.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Keyword Research & Strategy</a>
                  </h5>
                  <p>
                    Akiltech Inc is an SEO company that understands your niche and can boost your ranking for specific search terms. Your SEO specialist will review the keywords your website is currently ranking for, take your keyword wishlist and conduct additional keyword research to build a list that makes the most sense for your website and the competitive landscape.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/mobile.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Link Building</a>
                  </h5>
                  <p>
                    Backlinks are a huge component of ranking higher in search results, but not just any link! That’s why you should partner with an SEO company like Akiltech Inc that has a dedicated link building team and has connections with many high-quality and relevant websites.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ux.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Technical SEO</a>
                  </h5>
                  <p>
                    Rankings can improve with optimizations on your website’s back-end, such as improving page speed. By fixing these errors, it makes it easier on search engines when they crawl your website. And when you make search engines happy, your rankings can make you even happier!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/webservices.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Local SEO</a>
                  </h5>
                  <p>
                    Statistics show that 64 percent of customers search for local businesses online. Improve your company’s local SEO rankings with Google Business Profile or Google My Business (GMB) optimization and attract high-intent customers. We optimize your GMB profile and manage your online reputation to grow your local following.


                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/api.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">On-Page SEO</a>
                  </h5>
                  <p>
                    Enhance your online visibility and earn high-quality traffic. At Akiltech Inc, we ensure our SEO services are aligned with search engines’ best practices to boost your website’s trustworthiness. Our SEO experts publish unique, value-rich content, optimize your headlines and HTML tags and utilize high-resolution images.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Off-Page SEO </a>
                  </h5>
                  <p>
                    Off-page optimization is crucial in establishing brand credibility and increasing online exposure. Partner with our SEO company and let us help you demonstrate your industry expertise. We leverage social media marketing, link building and influencer marketing to generate qualified links and positive ratings.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Franchise SEO </a>
                  </h5>
                  <p>
                    Supercharge your franchise marketing efforts with Akiltech Inc’s SEO services for multiple-location businesses. We publish geo-specific content, leverage Nextdoor advertising and develop custom web designs. Akiltech Inc is the SEO firm that'll maximize your brand impact and increase your leads and sales.                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Content Writing </a>
                  </h5>
                  <p>


                    We have a team of writers that produce SEO-friendly
                    content for your website, whether that’s creating share-worthy blog
                    posts or optimizing pre-existing service and product pages with more
                    content. Choose our SEO firm to support your content needs
                    ,bolster your search marketing and let us help improve website conversions, rankings
                    and traffic with a well optimized SEO content strategy today.       </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Conversion Rate Optimization (CRO) </a>
                  </h5>
                  <p>
                    Increase your customers’ lifetime value and grow your online revenue with Akiltech Inc’s CRO services. Our SEO company integrates pay-per-click marketing with our SEO services to maximize your conversion opportunities. We perform website analyses, landing page optimization and A/B split testing.                  </p>
                </div>
              </div>
            </div>
            <p>Are you ready to get your website ranking better on Google?
              You’ve already read about our SEO services and approaches . Your website is ready for us to optimize, you are ready to hire us to write for you, or you are ready to put in the work. Perhaps, you have more questions we can answer for you in order to get the best possible return on your SEO investment. So, where do we go from here?</p>
            <p><strong>All of our SEO services begin with a 15-minute free briefing session with
              one of our SEO Specialists.We need to know a little bit more about you and your business .</strong></p>
          </div>{/* 
<p><strong> Content is King. We make sure you have unique and interesting content to attract
   customers and search engines. </strong></p>*/}
        </div>

      </section>
      <section className="about-area2 section-padding gray-bg">
        <div className="count-down-area section-padding border-bottom">
          <div className="container-fluid gray-bg">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-9  col-md-12">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle text-center mb-60">
                  <h2>A Website That Doesn't Bring Client Is Useless </h2>
                  <p className="mb-55">
                    Whether you’ve just launched a new website and want to get of on the right foot or your site has been up for a while but hasn’t been gaining traction.If you want us to help you get massive amounts of traffic, book a call.


                  </p>
                  <a href="https://calendly.com/mushaitapiwanashe/briefing-session" className="border-btn">
                    Get in Touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <FrequentQuestions />
      {/* <!-- Services Area End -->
    <!---Start a project--> */}
      <div
        style={{ marginTop: "0" }}
        className="count-down-area section-padding border-bottom"
      >




        {/* <!-- About Area End -->
      <!--Services Area 2 --> */}
        <section className="services-area2 section-padding2">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <span>Industries We Serve</span>
                  <h2>Software Development services </h2>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <p>
                    No matter the business, AkilTech has you covered with
                    industry compliant solutions, customized to your company’s
                    specific needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack1.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Home Service and Repair Companies</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack2.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Medical Providers</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack3.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Retail & Ecommerce</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack4.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#e">Travel & Hospitality</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack1.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#s">Professional Services</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack2.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Food, Drink, and Entertainment Services</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack3.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a"> Legal Services</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack4.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Real Estate</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="section-tittle section-tittle3 text-center pt-35">
                    <p>
                      Need a Technology support or to discuss a project ?{" "}
                      <a href="request">Get Started Now</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Apply2 />
      </div>


      <TopFooter />
    </main>
  );
};

export default Seo;
