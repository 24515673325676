import React from "react";
import NavPort from "./Navv";

const WebsitePortifolio = () => {
  return (
    <div className="container mb-30">
      <NavPort />
      <p className="text-center">
        Your website is your online business card. We’ll make it easy to
        read—and stand out. Meet our happy clients !!
      </p>
      <div class="container d-flex align-items-center justify-content-center flex-wrap">
        <div class="webp-box">
          <div class="webp-body">
            <div class="imgContainer">
              <img src="assets/img/Websites/2brConstruction.png" alt="" />
            </div>

            <div class="content d-flex flex-column align-items-center justify-content-center">
              <div>
                <h3 class="text-white fs-5">2brconstruction</h3>
                <p class="fs-6 text-white">
                  2BR Construction is a construction company that offers wendy
                  house and greenhouse tunnel construction coupled with
                  electrical installation and professional painting services{" "}
                </p>
                <p className="text-red">
                  <a href="https://www.2brconstruction.co.za/">
                    Visit www.2brconstruction.co.za
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="webp-box">
          <div class="webp-body">
            <div class="imgContainer">
              <img src="assets/img/Websites/Akiltech.png" alt="" />
            </div>
            <div class="content d-flex flex-column align-items-center justify-content-center">
              <div>
                <h3 class="text-white fs-5">Akiltech Inc.</h3>
                <p class="fs-6 text-white">
                  Akiltech Inc is a software development company that is in
                  business to help individuals and business with technology
                  needs
                </p>
                <p className="text-red">
                  <a href="https://www.akiltech.co/">Visit www.akiltech.co</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="webp-box">
          <div class="webp-body">
            <div class="imgContainer">
              <img src="assets/img/Websites/Akitel.png" alt="" />
            </div>
            <div class="content d-flex flex-column align-items-center justify-content-center">
              <div>
                <h3 class="text-white fs-5">Akitel Construction</h3>
                <p class="fs-6 text-white">
                  Akitel Construction is a South African-based, international
                  construction services company and is a leading builder in
                  diverse market segments.
                </p>
                <p className="text-red">
                  <a href="https://www.akitelconstruction.com/">
                    Visit www.akitelconstruction.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="webp-box">
          <div class="webp-body">
            <div class="imgContainer">
              <img src="assets/img/Websites/BakedYummies.png" alt="" />
            </div>
            <div class="content d-flex flex-column align-items-center justify-content-center">
              <div>
                <h3 class="text-white fs-5">Baked Yummies</h3>
                <p class="fs-6 text-white">
                  Baked yummies is an innovative and creative bakery that bakes
                  fresh and yummy customized cakes and cupcakes.
                </p>
                <p className="text-red">
                  <a href="https://www.bakedyummies.shop/">
                    Visit www.bakedyummies.shop
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="webp-box">
          <div class="webp-body">
            <div class="imgContainer">
              <img src="assets/img/Websites/southarctic.png" alt="" />
            </div>
            <div class="content d-flex flex-column align-items-center justify-content-center">
              <div>
                <h3 class="text-white fs-5">South Arctic</h3>
                <p class="fs-6 text-white">
                  South Arctic Airconditioning and Refridgeration specializes in
                  the installation and repair of airconditioning sysytems
                  ,coldrooms ,commercial, and domestic fridges.
                </p>
                <p className="text-red">
                  <a href="https://www.bakedyummies.shop/">
                    Visit www.southarctic.co.za
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WebsitePortifolio;
