// import React from "react";
//import PropTypes from "prop-types"#2d2d2d;

import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <div
      
      className="header-area header-transparent"
    >
      <div className="main-header header-sticky">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              {/* <div className="menu-wrapper d-flex align-items-center justify-content-between">
                <div className="left-content d-flex align-items-center"> */}
              {/* <!-- Main-menu --> */}
              <nav className="navbar navbar-expand-lg navbar-dark ">
                <Link className="navbar-brand" to="/">
                  <img src="assets/img/logo/logo.png" alt="" />
                </Link>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse  navbar-collapse "
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  id="navbarNav"
                  style={{ backgroundColor:"#ff9100" }}
                >
                  <ul className="navbar-nav align-right text-white" id="navigation" style={{textAlign:"center"}}>
                    <li className="nav-item">
                      <Link className="nav-link text-white" to="/">
                        Home
                      </Link>
                    </li>
                    
                   
                   
                   {/* <li className="nav-item">
                      <Link className="nav-link" to="/cloud-services-capetown">
                        Cloud Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/mobile-application-development-capetown">
                        Mobile App Development
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/google-ads-capetown">
                        Google Ads
                      
                    </li>
              */}<li class="nav-item dropdown">
    <a class="nav-link dropdown-toggleb text-white" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Services</a>
    <div class="dropdown-menu">
    <Link className="nav-link text-white" to="/seo-south-africa"><a class="dropdown-item" href="#">Search Engine Optimization</a>
    </Link> 
    <Link className="nav-link text-white" to="/services"><a class="dropdown-item" href="#">Software Development</a></Link>
    <Link className="nav-link text-white" to="/website-development-capetown"> <a class="dropdown-item" href="#">Website Development</a></Link>
    <Link className="nav-link text-white" to="/google-ads-agency"> <a class="dropdown-item" href="#">Google Ads</a></Link>

      <div class="dropdown-divider"></div>
{/*     <Link className="nav-link text-white" to="/social-media-marketing-south-africa">    <a class="dropdown-item" href="#">Social Media Marketing</a></Link> 
     <Link className="nav-link text-white" to="/search-engine-marketing-capetown">  <a class="dropdown-item" href="#">Search Engine Marketing</a></Link> 
            */}
    </div>
  </li><li className="nav-item">
                      <Link className="nav-link text-white" to="/akiltech-success-stories">
                        Success Stories 
                      </Link>
                    </li>
                   
                    <li className="nav-item">
                      <Link className="nav-link text-white" to="/career">
                        Careers
                      </Link>
                    </li>
                    
                    <li className="nav-item">
                      <Link className="nav-link text-white" to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link text-white" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* </div> */}
              {/* <!-- Button --> */}
              {/* <div className="buttons">
                  <ul>
                    <li className="button-header">
                      <Link to="/request" className="header-btn2 border-btn2">
                        Get Free Quote
                      </Link>
                    </li>
                  </ul>
                </div> */}
              {/* </div> */}
            </div>
          </div>
          {/* <!-- Mobile Menu --> */}
          {/* <div className="col-12">
            <div className="mobile_menu d-block d-lg-none"></div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {};

export default Header;
