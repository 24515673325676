import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Apply2 from "../commons/Apply2";

import TopFooter from "../commons/TopFooter";

const MobileApps = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      {/* <!-- Slider Area Start--> */}
      <div className="slider-area">
        <div className="single-sliders slider-height d-flex align-items-center">
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-6 col-md-6">
                <div className="hero-caption">
                  <h1>Search Engine Optimization</h1>

                  <p>
                  Invest in Your Future with an SEO Company that Gets Results.Akiltech Inc. helps small, medium and large businesses to rank as HIGH as possible on Google.
                  </p>
                  <ul>
                    <li> Increase your website traffic



</li>
                    <li> Get more leads & customers</li>
            
                    <li>Spend less on paid ads</li>
                  </ul>
                  <Link to="/services" className="btn hero-btn">
                  GET MY FREE PROPOSAL 
                  </Link>
                </div>
              </div>
              {/* <!-- hero shape 2--> */}
              <div className="hero-shape">
                <img src="assets/img/hero/hero-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="  d-flex ">
          <div className="container position-relative">
            <div className="row ">
              <div className="col-xl-5 col-lg-6 col-md-6  mouse_scroll  "></div>
              {/* <!-- hero shape 2--> */}
              <div className="">
                <div className="header-social text-white ">
                  <a className="text-white" href="#a">
                    Follow us on:
                  </a>
                  <a
                    href="https://www.facebook.com/akiltechspecialities/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/akiltech_inc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-instagram-square"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/akiltech-incoparated"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-linkedin-in"></i>
                  </a>
                  <a
                    href="https://twitter.com/AkilTech_inc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-twitter-square"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- social --> */}
      </div>

      {/* <!-- Slider Area End -->
    <!-- Services Area Start --> */}
      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-25">
                <span>What We Do</span>
                <h2>Turn Your Website to be your number 1 Salesman </h2>
              
              </div>
            </div>
            <p>Running a business in today’s competitive online landscape has become more difficult with the emergence of new technologies, regular algorithm updates, changing user behavior and increased competition. To see significant gains, you need to level up your SEO efforts.

Neglect your SEO strategy, and you risk facing technical site issues and Google penalties that could cause an undesirable drop in rankings, traffic and – ultimately – sales.

Don’t let poor SEO tactics hinder your digital transformation. Let Akiltech Inc, an expert SEO company, help you achieve long-term success with professional SEO solutions tailored to your brand needs:</p>
          </div>
          <div className="row">
            
           
          
          
           
          
          </div>
          <p>
            Regardless of the size of the project,our expert software engineers,
            developers and UI/UX designers will be there to guide you through
            your needs.We provide our services in different languages
            ,frameworks ,tools,databases , libraries .We believe in customizing
            each project based on special feature requirements and market
            conditions at competitive rates that's within your budget.
          </p>
          <a href="services" className="btn mt-20">
            Read More
          </a>
        </div>
        
      </section>
      <div className="container-fluid call-bg">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-9  col-md-12">
                  {/* <!-- Section Tittle --> */}
                  <div className="section-tittle text-center mb-60">
                    <h2>You’ve Finally Found the Right SEO Company With Answers </h2>
                    <p className="mb-55">
                    Akiltech Inc has the SEO solutions you’ve been searching for. Don’t let another day go by where your competitors are looking down on you! It’s time to be discovered!
                    </p>
                    <a href="request" className="border-btn">
                      Get Clients with SEO
                    </a>
                  </div>
                </div>
              </div>
            </div>
      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-25">
                <span>Our SEO Services</span>
                <h2>Increase Your Search Rankings and Get Discovered More Online </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/custom.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Keyword Research & Strategy</a>
                  </h5>
                  <p>
                  Akiltech Inc is an SEO company that understands your niche and can boost your ranking for specific search terms. Your SEO specialist will review the keywords your website is currently ranking for, take your keyword wishlist and conduct additional keyword research to build a list that makes the most sense for your website and the competitive landscape.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/mobile.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Link Building</a>
                  </h5>
                  <p>
                  Backlinks are a huge component of ranking higher in search results, but not just any link! That’s why you should partner with an SEO company like Akiltech Inc that has a dedicated link building team and has connections with many high-quality and relevant websites.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ux.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Technical SEO</a>
                  </h5>
                  <p>
                  Rankings can improve with optimizations on your website’s back-end, such as improving page speed. By fixing these errors, it makes it easier on search engines when they crawl your website. And when you make search engines happy, your rankings can make you even happier!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/webservices.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Local SEO</a>
                  </h5>
                  <p>
                  Statistics show that 64 percent of customers search for local businesses online. Improve your company’s local SEO rankings with Google Business Profile or Google My Business (GMB) optimization and attract high-intent customers. We optimize your GMB profile and manage your online reputation to grow your local following.


                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/api.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">On-Page SEO</a>
                  </h5>
                  <p>
                  Enhance your online visibility and earn high-quality traffic. At Akiltech Inc, we ensure our SEO services are aligned with search engines’ best practices to boost your website’s trustworthiness. Our SEO experts publish unique, value-rich content, optimize your headlines and HTML tags and utilize high-resolution images.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Off-Page SEO </a>
                  </h5>
                  <p>
                  Off-page optimization is crucial in establishing brand credibility and increasing online exposure. Partner with our SEO company and let us help you demonstrate your industry expertise. We leverage social media marketing, link building and influencer marketing to generate qualified links and positive ratings.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Franchise SEO </a>
                  </h5>
                  <p>
                  Supercharge your franchise marketing efforts with Akiltech Inc’s SEO services for multiple-location businesses. We publish geo-specific content, leverage Nextdoor advertising and develop custom web designs. Akiltech Inc is the SEO firm that'll maximize your brand impact and increase your leads and sales.                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Content Writing </a>
                  </h5>
                  <p>
                  We have a team of writers that produce SEO-friendly content for your website, whether that’s creating share-worthy blog posts or optimizing pre-existing service and product pages with more content. Search engines love a website that adds new content on a regular basis. Choose our SEO firm to support your content needs and bolster your search marketing.         </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Conversion Rate Optimization (CRO) </a>
                  </h5>
                  <p>
                  Increase your customers’ lifetime value and grow your online revenue with Akiltech Inc’s CRO services. Our SEO company integrates pay-per-click marketing with our SEO services to maximize your conversion opportunities. We perform website analyses, landing page optimization and A/B split testing.                  </p>
                </div>
              </div>
            </div>
          </div>
          <p>
            Regardless of the size of the project,our expert software engineers,
            developers and UI/UX designers will be there to guide you through
            your needs.We provide our services in different languages
            ,frameworks ,tools,databases , libraries .We believe in customizing
            each project based on special feature requirements and market
            conditions at competitive rates that's within your budget.
          </p>
          <a href="services" className="btn mt-20">
            Read More
          </a>
        </div>
        
      </section>
     
      {/* <!-- Services Area End -->
    <!---Start a project--> */}
      <div
        style={{ marginTop: "0" }}
        className="count-down-area section-padding border-bottom"
      >
        {/* <!--End Your Project-->
      <!-- About Area  --> */}
        <section className="about-area2 section-padding gray-bg">
          <div className="count-down-area section-padding border-bottom">
            <div className="container-fluid gray-bg">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-9  col-md-12">
                  {/* <!-- Section Tittle --> */}
                  <div className="section-tittle text-center mb-60">
                    <h2>Interested in discussing a project? </h2>
                    <p className="mb-55">
                      We accelerate the development of tech ecosystems and help
                      founders bring their ideas to life. In doing so, we create
                      opportunities for the right people to come together to
                      solve the problems of today, and prevent the ones of
                      tomorrow.
                    </p>
                    <a href="request" className="border-btn">
                      Get in Touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5 col-lg-5 col-md-10">
                {/* <!-- about-img --> */}
                <div className="about-img">
                  <img src="assets/img/gallery/about1.jpg" alt="" />
                </div>
                <div className="ratting-point">
                  <div className="features-ratting">
                    <img src="assets/img/icon/notification.svg" alt="" />
                  </div>
                  <div className="features-caption">
                    <h3>Data Insights</h3>
                    <p>we create relevant and engaging content</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-10">
                <div className="about-caption mb-50">
                  {/* <!-- Section Tittle --> */}
                  <div className="section-tittle mb-50">
                    <h2>
                    Be #1 & Rank
<br />
  Higher on Google !
                    </h2>
                  </div>
                  <div className="single-offers mb-20">
                    <div className="offers-cap">
                      <img src="assets/img/icon/notification1.svg" alt="" />
                      <h3>
                        <a href="#digital_marketing">Digital Marketing</a>
                      </h3>
                      <p>
                        Build your company’s online presence with a responsive
                        website suitable for all devices. Drive organic traffic
                        on keywords that matter to your business
                      </p>
                    </div>
                  </div>
                  <div className="single-offers">
                    <div className="offers-cap">
                      <img src="assets/img/icon/notification1.svg" alt="" />
                      <h3>
                        <a href="#google">Google Analytics</a>
                      </h3>
                      <p>
                        We implement Google Analytics on your website or app in
                        order to gather and measure critical data flows by your
                        website visitors to gain a deeper understanding of user
                        behavior and experience.
                      </p>
                    </div>
                  </div>
                  <a href="services" className="btn mt-20">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-25">
                <span>Why Search Engine Optimization is a Great Investment</span>
                <h2>We Practice What We Preach to Provide Maximum ROI </h2>
              
              </div>
            </div>
            <p>All business owners want more bang for their buck. All want better profit margins. SEO saves you money. Consider SEO as an employee: SEO never clocks out. SEO never calls in sick. SEO never takes a holiday. SEO works for you around the clock, even when you’re sleeping.

No, SEO won’t answer the phone. But it will keep the phone ringing. SEO done right will funnel customers to your website and put them in front of your products, services and brand.</p>
          </div>
          <div className="row">
            
           
          
          
           
          
          </div>
          <p>
            Regardless of the size of the project,our expert software engineers,
            developers and UI/UX designers will be there to guide you through
            your needs.We provide our services in different languages
            ,frameworks ,tools,databases , libraries .We believe in customizing
            each project based on special feature requirements and market
            conditions at competitive rates that's within your budget.
          </p>
          <a href="services" className="btn mt-20">
            Read More
          </a>
        </div>
        
      </section>
        {/* <!-- About Area End -->
      <!--Services Area 2 --> */}
        <section className="services-area2 section-padding2">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <span>Industries We Serve</span>
                  <h2>Software Development services </h2>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <p>
                    No matter the business, AkilTech has you covered with
                    industry compliant solutions, customized to your company’s
                    specific needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack1.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Industries & Manufacturing</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack2.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Education & Health</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack3.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Retail & Ecommerce</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack4.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#e">Travel & Hospitality</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack1.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#s">Media and Entertainment</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack2.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Public Services & FinTech</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack3.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a"> Software as a Service & SMEs</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack4.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Agriculture & Real Estate</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="section-tittle section-tittle3 text-center pt-35">
                    <p>
                      Need a Technology support or to discuss a project ?{" "}
                      <a href="request">Get Started Now</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Apply2 />
      </div>
      

      <TopFooter />
    </main>
  );
};

export default MobileApps;
