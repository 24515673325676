import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Apply2 from "../commons/Apply2";

import TopFooter from "../commons/TopFooter";

const SearchEngineMarketing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      {/* <!-- Slider Area Start--> */}
      <div className="slider-area">
        <div className="single-sliders slider-height d-flex align-items-center">
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-7 col-md-6">
                <div className="hero-caption">
                  <h1>CUSTOM WEB DEVELOPMENT</h1>

                  <p>
                  75% of people judge a business based on the appearance of its website.The right website will attract the right audience – which means increased traffic and conversions! Wondering how to make your mark online?</p>
                  
                  <Link to="/services" className="btn hero-btn">
                    BOOK FREE BRIEFING SESSION
                  </Link>
                </div>
              </div>
              {/* <!-- hero shape 2--> */}
             
            </div>
          </div>
        </div>
        <div className="  d-flex ">
          <div className="container position-relative">
            <div className="row ">
              <div className="col-xl-5 col-lg-6 col-md-6  mouse_scroll  "></div>
              {/* <!-- hero shape 2--> */}
              <div className="">
                <div className="header-social text-white ">
                  <a className="text-white" href="#a">
                    Follow us on:
                  </a>
                  <a
                    href="https://www.facebook.com/akiltechspecialities/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/akiltech_inc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-instagram-square"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/akiltech-incoparated"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="text-white fab fa-linkedin-in"></i>
                  </a>
                  <a
                    href="https://twitter.com/AkilTech_inc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className=" text-white fab fa-twitter-square"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- social --> */}
      </div>

      {/* <!-- Slider Area End -->
    <!-- Services Area Start --> */}
      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
          
            <h2>Our Web Development Solutions </h2>
            <p>
              First impressions matter, and what better way to create a positive one than by having a professionally designed and highly user-friendly website?

              At Akiltech Inc. we understand the importance of having a website for your business. More and more consumers are taking to the internet to find products and services, and without a website you are invisible to them, leaving them no choice but to go to your competitors.

              Having a wonderfully designed website is useless if it’s not converting visitors into customers! That is why we create websites that are optimized for conversion, giving you a website that is both beautifully designed and extremely functional.

              Our team of dedicated web developers handle every aspect on your behalf. This includes keeping servers running perfectly, handling website security and privacy, and maintaining your valuable databases. We do what it takes to guarantee you have a beautiful website that runs smoothly 24/7, 365.
            </p>
          </div>


         
        </div>

      </section>
      <div className="container-fluid call-bg">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9  col-md-12">
            {/* <!-- Section Tittle --> */}
            <div className="section-tittle text-center mb-60">
              <h2>If you want a website that speaks your language, contact us today. </h2>
              <p className="mb-55">
                Akiltech Inc has the SEO solutions you’ve been searching for. Don’t let another day go by where your competitors are looking down on you! It’s time to be discovered!
              </p>
              <a href="request" className="border-btn">
                Get Clients with SEO
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-25">
                <span>Websites That Sells</span>
                <h2>Proven Custom Web Development Solutions </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/custom.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Content Creation and Management</a>
                  </h5>
                  <p>
                    While the look and feel of your website help to establish its identity, it’s your content that gives it a voice! Our extensive team of talented and experienced content creators will craft engaging and informative copy that takes your website development to the next level.

                    They say ‘Content is King’ for a reason – good content marketing will always be part of effective web development. Our ability to offer design, SEO and content services all in one sets us apart from other web design companies in Cape Town and Johannesburg.

                    Get in touch today if you’d like to find out more about our comprehensive WordPress website design service.              </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/webservices.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">WordPress website design</a>
                  </h5>
                  <p>
                    

                    Another benefit for our clients is the ease with which they can add new content (like your latest blog post) themselves, without needing a third party to upload it on your behalf. And while the basics are very simple, if you want your WordPress-powered website to really stand out, you need expert level skills and experience.

                    That’s where our WordPress website design service becomes invaluable. Whether you already have a WordPress based website and need help adding more functionality and plug-ins, or like the idea of a website you can add fresh content to easily, this service is perfect for you.

                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            
           
           
           
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services"> Web Support & Maintenance Services </a>
                  </h5>
                  <p>
                  Developing a fully customized website shouldn't be the sole aim of a business owner. Maintaining an application or website timely help to keep it running smoothly and allows businesses to withstand competition. With our custom web support and maintenance services, we can help your business website stay updated as per the ongoing market trends.  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services"> Web Development Consulting </a>
                  </h5>
                  <p>
                  If you have an idea to develop a custom website and need professional assistance to execute it, we can help. Our team will help you evaluate your business goals and requirements while ensuring project feasibility and success. Seek our web development consulting services and turn your small business idea into a successful opportunity.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            
           
           
           
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">E-commerce Development </a>
                  </h5>
                  <p>
                    We have a team of writers that produce SEO-friendly content for your website, whether that’s creating share-worthy blog posts or optimizing pre-existing service and product pages with more content. Search engines love a website that adds new content on a regular basis. Choose our SEO firm to support your content needs and bolster your search marketing.         </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Website Design </a>
                  </h5>
                  <p>
                    Increase your customers’ lifetime value and grow your online revenue with Akiltech Inc’s CRO services. Our SEO company integrates pay-per-click marketing with our SEO services to maximize your conversion opportunities. We perform website analyses, landing page optimization and A/B split testing.                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            
           
           
           
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Web Migration & Upgradation </a>
                  </h5>
                  <p>
                  Business apps and websites need constant upgradation to reinvigorate digital experiences. With our custom web migration and upgradation services, you can make your existing business solutions result-driven and successful. Upgrade your business website to the newest industry standards and give your customers an amazing web experience. </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ecom1.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Blog Websites </a>
                  </h5>
                  <p>
                  Developing a fully customized website shouldn't be the sole aim of a business owner. Maintaining an application or website timely help to keep it running smoothly and allows businesses to withstand competition. With our custom web support and maintenance services, we can help your business website stay updated as per the ongoing market trends.</p>
                </div>
              </div>
            </div>
          </div>
          
        </div>
<p className="container"> If you’re looking for web development in Johannesburg, Cape Town or anywhere else in SA, our expert web developers will consider all of the above and more in creating a visually impressive, highly functional site for your brand.       We have a team of writers that produce SEO-friendly content for your website, whether that’s creating share-worthy blog posts or optimizing pre-existing service and product pages with more content. Search engines love a website that adds new content on a regular basis. Choose our SEO firm to support your content needs and bolster your search marketing.</p>
      </section>
      <section class="services-area3  ">
        <div className="container">
        <h2 class="h1 mb-3 text-center">Our Website Development Process</h2>
            <p class="  mb-5">We take a unique approach to website design in Cape Town, JHB, and across the country. No two companies are the same, which is why we always strive to meet each client’s unique needs and objectives through our thoroughly tested process.Does your online presence speak for itself? Is your offering so distinguishable that it will stand out amidst the crowd? While the internet is an abundance of information and resources, it is filled with competition continually striving to outcompete you. Wondering how to make your mark online? The solution is simple – an effective web design that will speak volumes for your brand. </p>
          <div class="row  align-items-lg-center">
           

            
              

                  
                   
                    <div class="col-lg-6 col-md-6 ">
                      <h4 class="mb-3">1. Briefing  Session</h4>
                      <p class="mb-0 ">We start development on your site by taking you through our brief and discussing your needs in detail.It is also about showcasing what is most important to your customers. A website might be great to look at, but the true win comes in when the correct audiences make it to the correct page. And even after, focusing upon the user’s experience once they arrive. We do this by identifying your business’ goals, as well as those of your consumers. Our developers will also discuss your brand identity, tone, and brand message.</p>
                    </div>
                 
                  <div class="col-lg-6 col-md-6 ">
                  
                   
                      <h4 class="mb-3">2. Dicovery Session</h4>
                      <p class="mb-0 ">Killer web design, together with skilled content, is guaranteed to generate large sums of traffic your way. But what’s even better than bucket-loads of potential customers finding their way to you? The right kind of traffic. A satisfied, guaranteed customer who’s actually going to stick around. Of which that traffic, transforms into increased revenue for you and your brand.</p>
                    
                  </div>
                  </div>
                  <div class="row  align-items-lg-center">
                  <div class="col-lg-6 col-md-6">
                   
                 
                      <h4 class="mb-3">3. Content Creation & Website Design</h4>
                      <p class="mb-0 ">We create an online presence and creative identity for your brand. We also create a most enjoyable experience, for those interacting with you. One which will be remembered long after your clients have logged off.Our copywriters are on hand to optimize any content received, as well as, produce content for your website or blog.</p>
                    
                  </div>
                  <div class="col-lg-6 col-md-6">
                   
                 
                      <h4 class="mb-3">4. Website Development</h4>
                      <p class="mb-0 ">We create an online presence and creative identity for your brand. We also create a most enjoyable experience, for those interacting with you. One which will be remembered long after your clients have logged off.Our copywriters are on hand to optimize any content received, as well as, produce content for your website or blog.</p>
                    
                  </div>
                </div>
             
             
                </div>
          
          
        
      </section>
      {/* <!-- Services Area End -->
    <!---Start a project--> */}
      <div
        style={{ marginTop: "0" }}
        className="count-down-area section-padding border-bottom"
      >
        {/* <!--End Your Project-->
      <!-- About Area  --> */}
        <section className="about-area2 section-padding gray-bg">
          <div className="count-down-area section-padding border-bottom">
            <div className="container-fluid gray-bg">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-9  col-md-12">
                  {/* <!-- Section Tittle --> */}
                  <div className="section-tittle text-center mb-60">
                    <h2>Interested in discussing a project? </h2>
                    <p className="mb-55">
                      We accelerate the development of tech ecosystems and help
                      founders bring their ideas to life. In doing so, we create
                      opportunities for the right people to come together to
                      solve the problems of today, and prevent the ones of
                      tomorrow.
                    </p>
                    <a href="request" className="border-btn">
                      Get in Touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </section>

        <section className="categories-area section-padding">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6 col-md-10">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle text-center mb-25">
                  <span>Why Search Engine Optimization is a Great Investment</span>
                  <h2>Frequently Bought Together </h2>

                </div>
              </div>
              <p>Website + SEO (the sooner you start SEO on your website the better as the informatino architecture (IA) of your website is important to the structure development and ranking of your website. This is an ongoing process of small incremental changes which lead to small increments over time, but result in large gains.</p>
              <p>Website + Google Ads (Often clients require a quick and effective solution to get traffic to their website. This can be achieved through Google Ads. It is a good option to get new business fast.)</p>
             <p>Website + SEO + Google Ads (If your budget allows, this is the best combination for short term and long term goals for website traffic, leads or sales.)</p>
            <p>All business owners want more bang for their buck. All want better profit margins. SEO saves you money. Consider SEO as an employee: SEO never clocks out. SEO never calls in sick. SEO never takes a holiday. SEO works for you around the clock, even when you’re sleeping.

                No, SEO won’t answer the phone. But it will keep the phone ringing. SEO done right will funnel customers to your website and put them in front of your products, services and brand.</p>
            </div>
            <div className="row">






            </div>
            
          </div>

        </section>
        
      <div className="container mt-3 mb-3 ">
        <div className="mt-2 mb-10 ml-10  heading ">
          {" "}
          <h2>Let’s Talk Digital Marketing</h2>{" "}
        </div>
        <div className="row mt-1 g-4 mb-50">
          <div className="col-md-4">
            <div className="card p-2">
              <div className="email mt-1">
                {" "}
                <span>
                  <span className="bodrd">
Market Research </span> and Analysis
                </span>
                
              </div>
              <div className="d-flex flex row align-items-center mr-2 mt-2">
                <div className="icons dummytext ">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Target Audience Identification: </span>Define your target market based on demographics, interests, behavior, and location.{" "}
                </div>
                <div className="icons">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Competitive Analysis</span>Study your competitors' strategies, strengths, and weaknesses.{" "}
                </div>
                <div className="icons">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Industry Trends</span>Stay updated with the latest trends in your industry.{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card p-2">
              <div className="email mt-1">
                {" "}
                <span>
                  <span className="bodrd1">Defining Goals  </span>and Objectives
                </span>
                
              </div>
              <div className="d-flex flex row align-items-center mr-2 mt-2">
                
                <div className="icons1">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>SMART Goals: </span>{" "}Set Specific, Measurable, Achievable, Relevant, and Time-bound goals.
                </div>
                <div className="icons1">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Key Performance Indicators (KPIs)</span>{" "} Identify metrics to measure the success of your strategies.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card p-2">
              <div className="email mt-1">
                {" "}
                <span>
                  <span className="bodrd5">Content Marketing</span>
                </span>
               
              </div>
              <div className="d-flex flex row align-items-center mr-2 mt-2">
                <div className="icons2">
                  {" "}
                  <i className="fa fa-check-circle"></i> <span>Content Strategy</span>{" "} 
                    Develop a content calendar that includes blogs, videos, infographics, and more.
                </div>
                <div className="icons2">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>SEO for Content</span>{" "} Use relevant keywords and optimize content for search engines. Distribution
                    : Leverage social media, email marketing, and other channels to distribute content.
                </div>
                <div className="icons2">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Off-Page audits</span>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-30">
            <div className="card p-2">
              <div className="email mt-1">
                {" "}
                <span>
                  <span className="bodrd2">Social Media </span> Marketing
                </span>
                
              </div>
              <div className="d-flex flex row align-items-center mr-2 mt-2">
                <div className="icons3">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Platform Selection</span> Choose platforms 
                    where your target audience is most active (e.g., Facebook, Instagram, LinkedIn).
                </div>
                <div className="icons3">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Content Plan</span>{" "} Create engaging, platform-specific content.
                </div>
                <div className="icons3">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Community Management</span>{" "}:
                     Actively engage with followers, respond to comments, and foster community.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-30">
            <div className="card p-2">
              <div className="email mt-1">
                {" "}
                <span>
                  <span className="bodrd3">
Paid </span> Advertising
                </span>
               
              </div>
              <div className="d-flex flex row align-items-center mr-2 mt-2">
                <div className="icons4">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Google Ads</span>{" "}: Use PPC (pay-per-click) campaigns to target specific keywords.
                </div>
                <div className="icons4">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Social Media Ads</span>{" "} Run targeted ads on social media platforms.
                </div>
                <div className="icons4">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Retargeting:</span>{" "} Implement retargeting ads to re-engage visitors who have left your site.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-30">
            <div className="card p-2">
              <div className="email mt-1">
                {" "}
                <span>
                  <span className="bodrd4">Google My Business </span>Optimization
                </span>
              
              </div>
              <div className="d-flex flex row align-items-center mr-2 mt-2">
                <div className="icons5">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Profile Setup and Verification
 </span>{" "} Create and verify your
                     Google My Business listing.
                </div>
                <div className="icons5">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Accurate Information</span>{" "} Ensure your business information (name, address, phone number) is accurate and consistent across the web.
Regular
                </div>
                <div className="icons5">
                  {" "}
                  <i className="fa fa-check-circle"></i>{" "}
                  <span>Updates</span>{" "}: Post updates, offers, events, and 
                    photos to engage with users and improve visibility.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        {/* <!-- About Area End -->
      <!--Services Area 2 --> */}
        <section className="services-area2 section-padding2">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <span>Industries We Serve</span>
                  <h2>Software Development services </h2>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <p>
                    No matter the business, AkilTech has you covered with
                    industry compliant solutions, customized to your company’s
                    specific needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack1.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Industries & Manufacturing</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack2.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Education & Health</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack3.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Retail & Ecommerce</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack4.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#e">Travel & Hospitality</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack1.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#s">Media and Entertainment</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack2.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Public Services & FinTech</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack3.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a"> Software as a Service & SMEs</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack4.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Agriculture & Real Estate</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="section-tittle section-tittle3 text-center pt-35">
                    <p>
                      Need a Technology support or to discuss a project ?{" "}
                      <a href="request">Get Started Now</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Apply2 />
      </div>


      <TopFooter />
    </main>
  );
};

export default SearchEngineMarketing;
