import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Apply2 from "./commons/Apply2";
import AkilCall from "./commons/AkilCall";
import Swimming from "../icons/swimming-pool-installation-capetown.png"
import Download from "../icons/download-case-study.png"
import Icone from "../icons/icon-software.png";
import Khloe from "../icons/khloe-home-decor-zimbabwe.png"
import Carports from "../icons/carports-capetown.png"
import Eastgate from "../icons/eastgate.png"
import TopFooter from "./commons/TopFooter";
import icon4 from "../icons/digital-transformation-problems.jpg";
import Webdev from "../icons/website-development.png";
import GoogleAd from "../icons/google-ads-agency.png";
import DigitalMarketing from "../icons/digital-marketing.png";
import KhloHome from "../Case-studies/khloe-home-decor.pdf";
import CarportCape from "../Case-studies/carport-capetown.pdf";
import SwimmingPool from "../Case-studies/swimming-pool-installation-capetown.pdf";
import EastgateDental from "../Case-studies/eastgate-dental.pdf";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      {/* <!-- Slider Area Start--> */}
      <div className="slider-area">
        <div className="single-sliders slider-height d-flex align-items-center">
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-7 col-md-7">
                <div className="hero-caption">
                  <h1>Is your digital growth and innovation dream out of reach?</h1>

                  <p>
                    You're not alone. Most businesses struggle to launch digital/software
                    products , increase visibilty and get clients online. Without
                    customer-centricity, the right software product or website
                    is never made. Let’s collaborate
                    to achieve success
                  </p>
                  <a href="https://calendly.com/mushaitapiwanashe/briefing-session" className="btn mt-20">
                    Schedule a Meeting
                  </a>
                </div>
              </div>
              {/* <!-- hero shape 2--> You may have found your people*/}
              <div className="hero-shape">
                <img src="assets/img/hero/hero-img.png" height={30} alt="" />
              </div>
            </div>
          </div>
        </div>


        {/* <!-- social --> */}
      </div>

      {/* <!-- Slider Area End -->
    <!-- Services Area Start --> */}

      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center categories-area section-padding">

            <h1 class="text-center">Lead In The Digital
              AGE, Effortlessly </h1>
            <p>The digital world is complicated, but running your business doesn't have
              to when you partner with the right <strong>digital transformation company</strong>. We’re now living in the age of digital Darwinism, where you
              evolve or you become irrelevant. In fact, 4 out of 10 companies will be dead
              in 10 years if they fail to drive <strong>digital transformation & innovation</strong>.
              . Most individuals and organizations face obstacles
              In <strong>digital transformation</strong> or <strong>digital growth strategies</strong> and some of the obstacles are as follows:</p>
            <div className="row mt-3"><div className="col-lg-6"><img className="img-fluid" src={icon4} /></div>
              <div className="col-lg-6">

                <div class="row justify-content-center section-title container mt-25">
                  <h3>Common Problems faced in the Digital Age:</h3>
                 
                    <div class="container">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="service-box">
                          <img src={Icone} height="30"/>                            <div class="service-content">
                              <p><strong>Fed up with freelance web developers who overpromise and underdeliver</strong>  </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="service-box">
                          <img src={Icone} height="30"/>                            <div class="service-content">
                              <p><strong>Struggle to launch digital products(Custom Software, Mobile Apps, Website Apps)</strong>  </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="service-box">
                          <img src={Icone} height="30"/>                            <div class="service-content">
                              <p><strong>Don’t know the best way to find a qualified web designer.</strong>  </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="service-box">
                          <img src={Icone} height="30"/>                            <div class="service-content">
                              <p><strong>Missing out on sales due to off-hours inquiries</strong>  </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="service-box">
                          <img src={Icone} height="30"/>                            <div class="service-content">
                              <p><strong> Fed up with freelance web developers who overpromise and underdeliver</strong>  </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="service-box">
                          <img src={Icone} height="30"/>                            <div class="service-content">
                              <p><strong>Wasting money on Google Ads & Facebook Ads that don't convert </strong>  </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="service-box">
                          <img src={Icone} height="30"/>                            <div class="service-content">

                              <p><strong>Website isn’t being found online, website doesn't bring clients and not seeing the desired results from your website.</strong></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="service-box">
                          <img src={Icone} height="30"/>
                            <div class="service-content">

                              <p><strong>Difficulty in advertising to the right people on social media and turning likes into paying customer relationships</strong></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  



                </div>
                <div class="row justify-content-center section-title mt-25">



                  {/* <div class="row justify-content-center section-title mt-20">

            <div class="col-lg-6 col-md ">
              <h6><img src={Icone} height="30"/> Difficulty attracting customers online  </h6>
              <p>
                68% of online experiences begin with a search engine & 75%
                of people don’t make it past the first page of Google.90%

                of pages get no organic search traffic from Google.The struggle to improve Google rankings and organic traffic.

                We help (people with websites) improve their search engine optimization (SEO) to rank on the first page of Google and increase website traffic.
              </p>*
            </div>
            <div class="col-lg-6 col-md ">
              <h6><img src={Icone} height="30"/>             Can't be found online? It's as if your business doesn't exist in the digital world.
</h6>
           {/*   <p>
                68% of online experiences begin with a search engine & 75%
                of people don’t make it past the first page of Google.90%

                of pages get no organic search traffic from Google.The struggle to improve Google rankings and organic traffic.

                We help (people with websites) improve their search engine optimization (SEO) to rank on the first page of Google and increase website traffic.
              </p>*
            </div>
            </div>
           {/*  <div class="col-lg-6 col-md ">
              <h6><img src={Icone} height="30"/> Tired of spending money on solutions that don't deliver?</h6>
             <p>
              We never compromise;we are committed to deliver the end result.Struggling to retain customers because of unreliable service?.Your business deserves better. Don't let old, unreliable systems hold you back. As
                professional business.
              </p>*
            </div>
            <div class="col-lg-6 col-md ">
              <h6><img src={Icone} height="30"/> Throwing money at ads with no return? </h6>
            {/*  <p>
              We never compromise;we are committed to deliver the end result.Struggling to retain customers because of unreliable service?.Your business deserves better. Don't let old, unreliable systems hold you back. As
                professional business.
              </p>
            </div>
*/}
                </div>

                <p className="mt-3">If you are experiencing any of the above problems, take a deep breath you have found
                  the right people. Akiltech was formed to lead
                  <strong>digital transformation in Africa</strong>. To achieve this , we have built an experienced team of software engineers, digital marketers and product developers to to assist organizations in
                  accelerating their <strong>“Digital Transformation”</strong>, <strong>"Digital Growth"</strong> and <strong>"Digital Innovation"</strong> by
                  leveraging the power of <a id="lik" href="#">software development</a>,<strong> software mantainance & support</strong>, <a id="lik" href="#">search engine marketing/digital marketing </a>,
                  <a id="lik" href="#"> website solutions</a> and <strong> business automations</strong></p>
                <p className="mb-2  text-bold">
                  <strong> Think of us as your tech-savvy digital transformation partners. Don’t let your tech idea die or another day go by where your competitors are looking down on you!
                  </strong></p>

                {/*    <div className="section-tittle text-center mb-60">
             

                  <a href="request" className="border-btn">
                    Talk To Us
                  </a>
            </div> */}
              </div></div>
          </div>




        </div>

      </section>
      <div className="container-fluid call-bg">

        {/* <!-- Section Tittle --><p>
                    There are hundreds, maybe even thousands of website designers in Cape Town and it is often very difficult for you to know which web design company to go for. You may think “I need to find website designers near me” or finding affordable website designers is your priority.It is important that your design communicates the correct information, and that the user-experience is a seamless one.s a framework to help understand our client's or prospect's needs, and their business problem so that we can properly architect an appropriate solution.
                    Need an affordable web designer or web development company to accurately represent your brand? Look no further! Click below to get a web design quote today. </p>
                 */}
        <div className="section-tittle call-action text-center mt-5 mb-60">
          <h2 className="text-white">A Partner of Choice for Digital Era Like No Other, Let's Collaborate
            To Achieve Success </h2>

          <a href="https://calendly.com/mushaitapiwanashe/briefing-session" className="border-btn2">
            Schedule a Meeting
          </a>
        </div>

      </div>

      <section className="categories-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">

              <div className="section-tittle text-center mb-25">
                <span>Elevating Brands through innovation in Digital Transformation.</span>
                <h2>We help organizations Establish, Maintain & Transform their Digital Footprint. </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/custom.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="https://calendly.com/mushaitapiwanashe/briefing-session">Custom Software Development & Mantainance</a>
                  </h5>
                  <p>
                    The digital world's complicated and choosing the right partner to fit your needs can make all the difference in the success of your project. Most people and businesses
                    have tech ideas and want to create softwares , mobile apps and tech ecosystems but they find themselves bound by budget,
                    hitting resistance, or held up by skills shortages. So because now you’re here, congratulations you have found your <strong>software development partner</strong>. AkilTech specializes in creating <strong>software solutions</strong> for
                    business and government departments to achieve operational excellence. <a id="idk" href="https://calendly.com/mushaitapiwanashe/briefing-session">Book a free briefing session</a> and let's discuss your <strong>tech , mobile app , custom software</strong> idea.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src={GoogleAd} alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="https://calendly.com/mushaitapiwanashe/briefing-session">Seach Engine Marketing/SEM</a>
                  </h5>
                  <p>
                    When it comes to planning your <strong>search marketing strategy</strong> it is important, from the very beginning, to lay out target audience personas including <strong>demographics ,psychographics and pain points</strong>. What do you want to achieve? What do you want to attract and which search phrases do they use ? How do you want people to think, feel, or react when they see your marketing? Based on your goals we work with you to achieve success through targeted marketing campaigns,
                     rich media ads with relevant content that speak directly to your desired audience.
                      The key to a successful <strong>marketing strategy</strong> is in the data!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/webservices.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Website Development </a>
                  </h5>

                  <p>Nowdays, with anyone and everyone  vying to be a top-tier website designer or developer, it can be overwhelming to choose the right one for your needs. You may find yourself asking, "Where can I find website
                    designers near me?" or prioritizing affordability when
                    searching for a <strong>web design company</strong>. But the key to a successful
                    website is not just about finding the right fit – it's about
                    creating a design that effectively communicates your message
                    and provides a seamless user experience. That's why we use
                    a unique framework to understand our clients' needs and business
                    problems, allowing us to create a tailored solution. Don't settle
                    for just any web designer ,<a href="#" id="">Schedule a free briefing session</a> with
                    a consultant from one of <strong>reputable web design and development company</strong> that will accurately represent
                    your brand and meet your needs .</p></div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/ux.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">UI/UX Design</a>
                  </h5>
                  <p>
                    Do we understand our user, their needs, and their challenges? These are the questions we ask each other in a process that ensures we are building a solution that people want and need! It helps us understand how users engage and interact with a website and consider page speed, mobile responsiveness, menu/header layout and URL structure. Our experts design and create digital experiences that are
                    purposeful and enjoyable for end-users, as well as for our
                    clients.Your users are our first priority, and our minimum
                    goal is a client’s maximum satisfaction. We immerse ourselves in your business to define, design and develop meaningful UX/UI across all platforms and touchpoints.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <div className="call-blue text-center">

        <h2 className="text-white text-center mt-5">
          Do you have a Software , Mobile App or Website Request?
        </h2>
        <a href="https://calendly.com/mushaitapiwanashe/briefing-session" className="btn mt-20">
          Schedule a Meeting
        </a>
      </div>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src={Webdev} alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Search Engine Optimization</a>
                  </h5>

                  <p>Countless individuals and companies aspire to <strong>attract customers</strong> through their
                    websites; a goal that can often seem elusive. That's why discovering a
                    company that not only helps developing a website, but also successfully
                    converts those visitors into <strong>paying customers</strong> is like hitting
                    the jackpot. Have you ever wondered if your business or brand appears
                    on the <strong>first page of search engine results </strong>
                    for industry-specific keywords? If not, you're passing up numerous
                    potential leads. <a id="lik" href="#">Search engine optimization </a>
                    is no longer a passing trend, and neglecting it will hinder the growth
                    of your business. Let us take charge and propel your website to the
                    <strong> top of Google's rankings</strong>, organically. By doing
                    so, you'll not only gain MORE leads but also save BIG on Google Ads.
                    Don't let this opportunity slip away!</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src="assets/img/icon/api.png" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="services">Business Automations</a>
                  </h5>
                  <p>
                    With <strong>Business Automation</strong>, you can minimize human errors, accelerate workflows, enhance data accuracy, and free up valuable resources to focus on strategic initiatives. Whether it’s automating customer support, inventory management, financial processes, or any other business function, our solutions enable you to streamline operations, save time, and drive growth. Business Automation refers to the use of advanced technologies and software solutions to streamline and optimize various operational processes within an organization. By automating repetitive and time-consuming tasks, businesses can achieve <strong>increased efficiency</strong>,
                     reduced errors, and <strong>improved productivity</strong>. You have something
                      in mind ? Schedule a free brieing session , let's hear how you want to optimize
                       your business or organization
                  </p>
                </div>
              </div>
            </div>

          </div>
          <div className="row">

            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-cat text-center mb-20">
                <div className="cat-icon">
                  <img src={DigitalMarketing} alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="https://calendly.com/mushaitapiwanashe/briefing-session">Digital Marketing</a>
                  </h5>

                  <p>Are you facing challenges with your online marketing efforts? Don't worry, you're not alone. It's common to struggle with low visibility, underperforming <strong>ad campaigns</strong>, and disappointing conversion rates. Thankfully, Akiltech Inc. is here to offer a helping hand. Our <strong>digital marketing services</strong> are designed to elevate your business and boost your leads and revenue. Our expertise lies in paid advertising, creating <strong>custom website designs</strong>, and developing high-converting landing pages. Join us on our mission to be an essential contributor to your success story.</p>
                </div>
              </div>

            </div>


          </div>
          <p>
            Imagine building a <strong>software/mobile app or website</strong> like you would build your family home.  Would you hire newbies who build houses
            in their spare time? Or would you <strong>hire a team of expert professionals</strong>
            with years of skill in their respective fields to ensure quality care
            was taken with your home? You wouldn’t take shortcuts and freebies to
            build your family home. Why do that with your tech idea or company’s website”
            Isn’t your business – like your home - worth the investment? Schedule a free briefing session and get custom software development ,mobile app , website ,search engine & digital marketing solutions  from Akiltech Inc. . We believe in customizing
            each project based on special feature requirements and market
            conditions at competitive rates that's within your budget.


          </p>
          <a href="https://calendly.com/mushaitapiwanashe/briefing-session" className="btn mt-20">
            Schedule a Meeting
          </a>
        </div>

      </section>
      <div className="call-blue text-center">

        <h2 className="text-white text-center mt-5">
          We are passionate about serving our clients !
        </h2>
        <h5 className="text-white text-center">Case Studies - Our successful campaigns</h5>

      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">
            <img src={Swimming} className="img-fluid" alt="" />
            <div className="cat-cap">
              <h4 className="mt-3">
                <a href="services"><strong>Search Marketing Case Study: Pool Installation and Repairs</strong></a>
              </h4>

              <p className="mt-3">A South African company offering pool installation , pool building , pool repairs to pool cleaning
                services commissioned Akiltech’s services to setup it's digital footprint ,online presence , grow it’s rankings on Google and  increase engagement with its target audience across digital platforms. </p>
            </div>
            <a href={SwimmingPool} download="Swimming Pool Installation Capetown Case Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">
            <img src={Carports} className="img-fluid" alt="" />
            <div className="cat-cap mt-3">
              <h4>
                <a href="services"><strong>Search Engine Optimization Case Study: Carports & Shadeports Contractors</strong></a>
              </h4>

              <p className="mt-3">A South African company providing residential and commercial carports and shadeports installation services in Western Cape. It commissioned Akiltech’s services to setup online presence, increase Google market share, rankings and online conversions. </p>
            </div>
            <a href={CarportCape} download="Carports-Capetown-Case-Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">

            <img src={Khloe} className="img-fluid" alt="" />

            <div className="cat-cap mt-3">
              <h4>
                <a href={KhloHome} download="Khole-home-decor"><strong>Website Dev & GBM Case Study: Home Interiors | Curtains</strong></a>
              </h4>
              <p className="mt-3">
                Khloe's Home & Décor is a Zimbabwean private owned company specialising in Home Interiors.Core business is curtaining,  providing to both households and organisations. It was founded in 2017 by a young woman who had a passion for home decor and office improvements.Khloe commissioned Akiltech’s services to setup google and internet presence.
              </p>
            </div>
            <a href={KhloHome} download="Khole-home-decor Case Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">

            <img src={Eastgate} className="img-fluid" alt="" />

            <div className="cat-cap mt-3">
              <h4>
                <a href="services"><strong>Website Dev Case Study: Pool Installation and Repairs</strong></a>
              </h4>
              <p>
                A Dental practice in Harare CBD which provides a full spectrum of dental services. It commissioned Akiltech’s services to setup it's digital footprint ,online presence and increase it’s visibility on Googele.
              </p>
            </div>
            <a href={EastgateDental} download="Eastgate Dental Case Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>



      </div>
      <section className="services-area2 section-padding2">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle section-tittle2 mb-55">
                <span>The guaranteed formula </span>
                <h2>CLARITY IN THE CHAOS</h2>
              </div>
            </div>

            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
              {/* <!-- Section Tittle -<p>
                    Many people and businesses wish to get clients through their website .It is a wish come true to comeacross a company that can assist and convert website traffic to customers.Are you on the first page of the search engine results pages (SERPs) for keywords that match your business or brand? If not, you’re missing out on valuable leads. Search engine optimization isn’t a fad and it’s no longer an option to simply not think about it if you want to grow your business. Let's push your website to the top of Google (organically). Get MORE leads and spend LESS money on Google Ads!.
                  </p>-> */}
              <div className="section-tittle section-tittle2 mb-55">
                <p>
                  Feeling swamped with all the tech talk? We're here to translate so you can get back to business. You might think "Is this even for me? I'm not a tech person". Don't worry, we're not just consultants; we're your business digital growth , partners, providing tailored strategies in plain English.
                </p>
              </div>
            </div>
          </div>
          <div class="row  section-title mt-20">
            <div class="col-md col-lg-12">
              <h2 className="text-white">1. Start with 'Why?'</h2>
              <p className="text-white">
                Our first step in designing the perfect solution is to fully grasp your business objectives
                and your customers' needs. This enables us to accurately define the problem at hand and your expectations ensuring that we
                are on the right track. By collaborating in this way, we are able to uncover
                your current position. Our ultimate goal is not just to help your business survive, but
                to see it thrive.
              </p>
            </div></div>
          <div class="row  section-title mt-20">
            <div class="col-md col-lg-12">
              <h2 className="text-white">2. Then get building!</h2>
              <p className="text-white">
                By harnessing the power of Design Thinking and incorporating user experience best practices, we craft impactful and effective digital strategies, tech products, custom software, and websites. Our innovative approach ensures scalability and promotes efficient growth. We understand the importance of establishing a strong emotional connection between your digital products and your target audience. After all, humans are emotional creatures and it is crucial for your software product or online presence to resonate with them.
              </p>
            </div></div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-cat mb-30 text-center">
                <div className="cat-icon">
                  <img src="assets/img/icon/stack1.svg" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="https://calendly.com/mushaitapiwanashe/briefing-session">Schedule a Meeting</a>
                  </h5>
                  <p>It all begins with understanding what you need.  <a href="https://calendly.com/mushaitapiwanashe/briefing-session">Book a briefing session</a> with us where we dissect your current digital challenges and understand what are you looking into our services.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-cat mb-30 text-center">
                <div className="cat-icon">
                  <img src="assets/img/icon/stack2.svg" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="#r">Get a Custom Digital Strategy</a>
                  </h5>
                  <p>We’ll develop a unique plan to navigate the digital hurdles or challenges  specific to your business.An  Blueprint of the next action points , user stories and flows , customer profiles and trajectory to your digital sucess </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="single-cat mb-30 text-center">
                <div className="cat-icon">
                  <img src="assets/img/icon/stack3.svg" alt="" />
                </div>
                <div className="cat-cap">
                  <h5>
                    <a href="#r">Hit Your Business Milestones</a>
                  </h5>
                  <p>Implement the plan and watch your digital dream come to live or  business grow, hitting targets and exceeding expectations</p>
                </div>
              </div>
            </div>


            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div className="section-tittle section-tittle3 text-center pt-35">
                  <p>
                    Need a Technology support or to discuss a project ?We help you sift through all the tech jargon and focus on what will actually help your business grow.{" "}
                    <a href="https://calendly.com/mushaitapiwanashe/briefing-session">Get Started Now</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        style={{ marginTop: "0" }}
        className="count-down-area section-padding border-bottom"
      >
        {/* <!--End Your Project-->
      <!-- About Area  --> */}
        <section className="about-area2 section-padding white-bg">

          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5 col-lg-5 col-md-10">
                {/* <!-- about-img --> */}
                <div className="about-img">
                  <img src="assets/img/gallery/about1.jpg" alt="" />
                </div>
                <div className="ratting-point">
                  <div className="features-ratting">
                    <img src="assets/img/icon/notification.svg" alt="" />
                  </div>
                  <div className="features-caption">
                    <h3>WE SPEAK BUSINESS, NOT JUST TECH</h3>
                    <p>we create relevant and engaging content</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-10">
                <div className="about-caption mb-50">
                  {/* <!-- Section Tittle --> */}
                  <div className="section-tittle mb-50">
                    <h2>
                      A Website That Doesn't
                      <br />
                      Bring  Client Is Useless
                    </h2>
                  </div>
                  <div className="single-offers mb-20">
                    <div className="offers-cap">
                      <img src="assets/img/icon/notification1.svg" alt="" />
                      <h3>
                        <a href="#digital_marketing">SEO – what’s the fuss?</a>
                      </h3>
                      <p>
                        Running a business and getting quality clients in today’s
                        competitive online landscape has become more difficult.To stay ahead, it's crucial to up your game when it comes to SEO. Without a strong SEO strategy in place, you risk fading into obscurity and falling behind the competition.</p>
                    </div>
                  </div>
                  <div className="single-offers">
                    <div className="offers-cap">
                      <img src="assets/img/icon/notification1.svg" alt="" />
                      <h3>
                        <a href="#google">Get A Custom Roadmap</a>
                      </h3>
                      <p>
                        With a concrete plan in place, we can elevate your online visibility and effectively direct customers to your website, exposing them to your products, services, and brand. Our top-notch SEO services are guaranteed to keep your phone ringing with potential customers. Schedule your consultation to meet with a member of our SEO team within a couple of business days.                      </p>
                    </div>
                  </div>
                  <a href="services" className="btn mt-20">
                    Schedule Free SEO Consulting
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* <!-- About Area End -->
      <!--Services Area 2 --> */}
        <section className="services-area2 section-padding2">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <span>Industries We Serve</span>
                  <h2>Software Development services </h2>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-11">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle2 mb-55">
                  <p>
                    No matter the business, AkilTech has you covered with
                    industry compliant solutions, customized to your company’s
                    specific needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack1.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Industries & Manufacturing</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack2.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Education & Health</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack3.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#r">Retail & Ecommerce</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="single-cat mb-30 text-center">
                  <div className="cat-icon">
                    <img src="assets/img/icon/stack4.svg" alt="" />
                  </div>
                  <div className="cat-cap">
                    <h5>
                      <a href="#e">Travel & Hospitality</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack1.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#s">Media and Entertainment</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack2.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Public Services & FinTech</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack3.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a"> Software as a Service & SMEs</a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="single-cat mb-30 text-center">
                    <div className="cat-icon">
                      <img src="assets/img/icon/stack4.svg" alt="" />
                    </div>
                    <div className="cat-cap">
                      <h5>
                        <a href="#a">Agriculture & Real Estate</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="section-tittle section-tittle3 text-center pt-35">
                    <p>
                      Need a Technology support or to discuss a project ?{" "}
                      <a href="request">Get Started Now</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Apply2 />
      </div>


      <TopFooter />
    </main>
  );
};

export default Home;
