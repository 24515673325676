import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Apply2 from "./commons/Apply2";
import Swimming from "../icons/swimming-pool-installation-capetown.png"
import Download from "../icons/download-case-study.png"
import Icone from "../icons/icon-software.png";
import Khloe from "../icons/khloe-home-decor-zimbabwe.png"
import Carports from "../icons/carports-capetown.png"
import Eastgate from "../icons/eastgate.png"
import TopFooter from "./commons/TopFooter";
import icon4 from "../icons/digital-transformation-problems.jpg";
import Webdev from "../icons/website-development.png";
import GoogleAd from "../icons/google-ads-agency.png";
import DigitalMarketing from "../icons/digital-marketing.png";
import KhloHome from "../Case-studies/khloe-home-decor.pdf";
import CarportCape from "../Case-studies/carport-capetown.pdf";
import SwimmingPool from "../Case-studies/swimming-pool-installation-capetown.pdf";
import EastgateDental from "../Case-studies/eastgate-dental.pdf";

const SucessStories = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      {/* <!-- Hero area Start--> */}
      <div className="slider-area">
        <div className="single-sliders slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-6 col-md-6">
                <div className="hero-caption hero-caption2">
                  <h2>Digital Growth Success Stories</h2>
                  <p>
                  Read some of our business success stories below to learn more about how our digital strategies can empower your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--  Hero area End --> */}
      <div className="container">
        
        <div className="row mt-50 mb-50">
          <div className="col-md align-items-center">
          <h2>Client Success Stories</h2>
            <p>
            Explore our collection of success stories that showcase how our digital
             solutions ,search engine marketing strategies and  modern website designs
              have empowered businesses. From building brand new digital footprints to
               enhancing existing ones, our case studies reveal the impact of our digital
                strategies.
            </p>
            
          </div>
          <div className="col-md  ">
          <h2>Solve real business problems</h2>
            <p className="text-left">
              Our aim is to lead
                  digital transformation in Africa. We assist organizations in
                  accelerating their “Digital Transformation”, "Digital Growth" and "Digital Innovation" by
                  leveraging the power of <a id="lik" href="#">software development</a>,<strong> software mantainance & support</strong>, <a id="lik" href="#">search engine marketing/digital marketing </a>,
                  <a id="lik" href="#"> website solutions</a> and <strong> business automations</strong>.
            </p>
          </div>
        </div>
       
       
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">
            <img src={Swimming} className="img-fluid" alt="" />
            <div className="cat-cap">
              <h4 className="mt-3">
                <a href="services"><strong>Search Marketing Case Study: Pool Installation and Repairs</strong></a>
              </h4>

              <p className="mt-3">A South African company offering pool installation , pool building , pool repairs to pool cleaning
                services commissioned Akiltech’s services to setup it's digital footprint ,online presence , grow it’s rankings on Google and  increase engagement with its target audience across digital platforms. </p>
            </div>
            <a href={SwimmingPool} download="Swimming Pool Installation Capetown Case Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">
            <img src={Carports} className="img-fluid" alt="" />
            <div className="cat-cap mt-3">
              <h4>
                <a href="services"><strong>Search Engine Optimization Case Study: Carports & Shadeports Contractors</strong></a>
              </h4>

              <p className="mt-3">A South African company providing residential and commercial carports and shadeports installation services in Western Cape. It commissioned Akiltech’s services to setup online presence, increase Google market share, rankings and online conversions. </p>
            </div>
            <a href={CarportCape} download="Carports-Capetown-Case-Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">

            <img src={Khloe} className="img-fluid" alt="" />

            <div className="cat-cap mt-3">
              <h4>
                <a href={KhloHome} download="Khole-home-decor"><strong>Website Dev & GBM Case Study: Home Interiors | Curtains</strong></a>
              </h4>
              <p className="mt-3">
                Khloe's Home & Décor is a Zimbabwean private owned company specialising in Home Interiors.Core business is curtaining,  providing to both households and organisations. It was founded in 2017 by a young woman who had a passion for home decor and office improvements.Khloe commissioned Akiltech’s services to setup google and internet presence.
              </p>
            </div>
            <a href={KhloHome} download="Khole-home-decor Case Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-left mb-20">

            <img src={Eastgate} className="img-fluid" alt="" />

            <div className="cat-cap mt-3">
              <h4>
                <a href="services"><strong>Website Dev Case Study: Pool Installation and Repairs</strong></a>
              </h4>
              <p>
                A Dental practice in Harare CBD which provides a full spectrum of dental services. It commissioned Akiltech’s services to setup it's digital footprint ,online presence and increase it’s visibility on Googele.
              </p>
            </div>
            <a href={EastgateDental} download="Eastgate Dental Case Study" className="btn mt-20">
              DOWNLOAD CASE STUDY <img src={Download} />
            </a>
          </div>
        </div>



      </div>
      <div className="container-fluid call-bg">

        {/* <!-- Section Tittle --><p>
                    There are hundreds, maybe even thousands of website designers in Cape Town and it is often very difficult for you to know which web design company to go for. You may think “I need to find website designers near me” or finding affordable website designers is your priority.It is important that your design communicates the correct information, and that the user-experience is a seamless one.s a framework to help understand our client's or prospect's needs, and their business problem so that we can properly architect an appropriate solution.
                    Need an affordable web designer or web development company to accurately represent your brand? Look no further! Click below to get a web design quote today. </p>
                 */}
        <div className="section-tittle call-action text-center mt-5 mb-60">
          <h2 className="text-white">A Partner of Choice for Digital Era Like No Other, Let's Collaborate
            To Achieve Success </h2>

          <a href="https://calendly.com/mushaitapiwanashe/briefing-session" className="border-btn2">
            Schedule a Meeting
          </a>
        </div>

      </div>
      {/* <!-- Great Stuffs End -->
  <!-- More about info --> */}
      <section
        className="collection section-img-bg2  section-over1"
        data-background="assets/img/gallery/section_bg01.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="single-question text-center">
                {/* <!-- Video icon --> */}
                <div className="video-icon mb-40">
                  <Link
                    className="popup-video btn-icon"
                    to="/" //https://www.youtube.com/watch?v=up68UAfH0d0"
                    data-animation="bounceIn"
                    data-delay=".4s"
                  >
                    <i className="fas fa-play"></i>
                  </Link>
                </div>
                <h2
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".1s"
                >
                  Digital Innovation Agency for your Business & Startup
                </h2>
                <Link
                  to="/about"
                  className="white-btn wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay=".4s"
                >
                  Get Started Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- about info End --> */}
    </main>
  );
};

export default SucessStories;
