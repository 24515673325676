import React, { useEffect } from "react";
import Focus from "./Focus";
import Values from "./Values";
import Vision from "./Vision";
import Mission from "./Mission";
import AkilCall from "../commons/AkilCall";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      {/* <!-- Hero area Start--> */}
      <div className="slider-area">
        <div className="single-sliders slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-7 col-md-6">
                <div className="hero-caption hero-caption2">
                  <h2>About Us</h2>
                  <p>
                 Akiltech Inc. was formed with the vision to offer best in class
digital solutions and technology services to organizations, big or small. To
achieve this, we created due to our team of
                    skilled top software developers, professionals, key business
                    insights, and a dedicated working process to assist organizations in
accelerating their “Digital Transformation” by leveraging the power of
Analytics & AI. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--  Hero area End -->
  <!--Intro start here-->Content is the most important part of a website, it's what converts visitors into loyal buying customers. We honestly believe that a strong content strategy is one of the best SEO strategies there is. At SEO Traffic, we have top notch writers that have experience at writing for a variety of different niches. The well researched content we provide is always top notch and loved by readers and search engines. Let us help improve website conversions, rankings and traffic with a well optimized SEO content strategy today. Simply click here. */}
      <div className="container section-title mt-60 mb-60">
        <Vision />
        <Mission />

        <p>
          So, What is the value Akiltech’s business seeks to create?{" "}
          <large className="font-weight-bold">
            Accelerate digital innovation and transformation
          </large>
        </p>
        <p>
          How does AkilTech seek to create that value?{" "}
          <large className="font-weight-bold">
            Offering outstanding software engineering expertise{" "}
          </large>
        </p>
        <p>
          {" "}
          For Whom does the Akiltech seek to create that value?{" "}
          <large className="font-weight-bold">
            Business enterprises ,individuals and all organizations seeking
            software services.
          </large>
        </p>

        <Values />
        <Focus />
      </div>
      <div>
      <h2 class="mt-35" style={{ backgroundColor: "#ff9100" }}>
      Engagement Models
      </h2>
      <div class="row justify-content-center section-title mt-25">
        <div class="col-md ">
          <h2>1. Managed Services</h2>
          <p>
          Our distinctive managed services offer you complete peace of mind. In this model, you decide the scope of work as per your needs while we decide on capacity required to do so. This model works well when you are not sure of the quantum of work involved.
          </p>
        </div>

        <div class="col-md ">
          <h2>2. Consulting & Advisory</h2>
          <p>
          Our consulting & advisory services offer you to analyze, strategize, plan options for your digital journey. This model works well when you are looking for improvement areas in your processes or when you want to start something afresh.
          </p>
        </div>

        <div class="col-md ">
          <h2>3. Think Big</h2>
          <p>
            We think boldly.We don’t make excuses; instead, we think of positive
            and realistic ways to get things done.We do not think small for
            safe, risk-free accomplishments. We think extensively and come up
            with the ways to get things done, instead of finding excuses.
          </p>
        </div>
        <div class="row justify-content-center section-title mt-20">
          <div class="col-md ">
            <h2>4. Commitment</h2>
            <p>
              We never compromise;we are committed to deliver the end result. As
              professional business , we are determined to accomplish (without
              compromise) and never give up, even it takes extra time to do so.
            </p>
          </div>
          <div class="col-md ">
            <h2>5. Professionalism</h2>
            <p>
              In our company structure and traditions, Professionalism is seen
              in our dedication to professional growth.We want to achieve
              Akiltech Vision by pursuing uniqueness and high degree of
              professionalism, not by a broad and shallow approach.
            </p>
          </div>
          <div class="col-md ">
            <h2>6. Evolution</h2>
            <p>
              We continue to evolve with change.We are ever evolving; we should
              not be comfortable with status quo, we should learn from past
              mistakes and exceed our past success.
            </p>
          </div>
        </div>
      </div>
    </div>
      <section className="container gray-bg">
        <div className="row mt-50 mb-50 ">
          <div className="col-md cat-cap col-lg-6 text-left ">
            <h2>Love building digital products?</h2>
            <p>
              We’re always on the lookout for talented, ambitious team members.
              Get in touch to discuss opportunities.
            </p>
            <a href="apply" className="border-btn">
              Careers
            </a>
          </div>
          <div className="col-md col-lg-6 text-center text-align-center">
            <img
              src="assets/img/services/coder.jpg"
              height="200"
              width="200"
              alt=""
            />{" "}
          </div>
        </div>
      </section>
      {/* <!-- Count Down Start --> */}
      <div className="count-down-area section-padding ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9  col-md-12">
              {/* <!-- Section Tittle --> */}
              <div className="section-tittle text-center mb-60">
                <h2>Interested in discussing a project? </h2>
                <p className="mb-55">
                  We accelerate the development of tech ecosystems and help
                  founders bring their ideas to life. In doing so, we create
                  opportunities for the right people to come together to solve
                  the problems of today, and prevent the ones of tomorrow.
                </p>
                <a href="contact" className="border-btn">
                  Get in Touch
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Count Down End -->
  <!-- About Area  -->


  <!-- About Area End -->
  <!-- More about info --> */}
      <section
        className="container-fluid collection section-img-bg2  section-over1"
        data-background="assets/img/gallery/section_bg01.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="single-question text-center">
                {/* <!-- Video icon --> */}
                <div className="video-icon mb-40">
                  <a
                    className="popup-video btn-icon"
                    href="/" //https://www.youtube.com/watch?v=up68UAfH0d0
                    data-animation="bounceIn"
                    data-delay=".4s"
                  >
                    <i className="fas fa-play"></i>
                  </a>
                </div>

                <h2
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".1s"
                >
                  Digital Innovation Agency for your Business & Startup
                </h2>
                <a
                  href="about"
                  className="white-btn wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay=".4s"
                >
                  Get Started Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- about info End --> */}
    </main>
  );
};

export default About;
